import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RegisterRouterService {
  private _showCloseButtonSubject = new BehaviorSubject<boolean>(true);
  public showCloseButton$ = this._showCloseButtonSubject.asObservable();

  public updateShowCloseButon(showCloseButton: boolean): void {
    this._showCloseButtonSubject.next(showCloseButton);
  }
}
